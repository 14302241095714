import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
import { locale as enLang } from './modules/i18n/vocabs/en';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { AuthService, UserType } from '@core/auth';
import { Observable, Subscription } from 'rxjs';
import { Metrika } from 'ng-yandex-metrika';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  user$: Observable<UserType>;
  private unsubscribe: Subscription[] = [];
  
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private auth: AuthService,
    private ym: Metrika
  ) {
        this.translationService.loadTranslations(
      enLang
    );

  }

  ngOnInit() {
    const oldDomain = 'seller.indigolab.io';
    const newDomain = 'seller.ilai.io';
    if (window.location.hostname === oldDomain) {
      window.location.href = window.location.href.replace(oldDomain, newDomain);
    }
    this.modeService.init();
    this.user$ = this.auth.currentUserSubject.asObservable();
    this.user$.subscribe(user => {
      if (user){
        const userId = user.id;
        this.ym.setUserID(String(userId)); 
        this.ym.userParams({ 
          UserID: String(userId)
        }); 
      }
    });
  }  

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
} 
