import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  getCurrentUser(): Observable<UserModel> {
    return this.http.get<UserModel>(`/profile`);
  }

  createRequestAppointLink(typeAppoint: number) {
    let data = { typeAppoint: typeAppoint };
    return this.http.post(`/profile/create-appoint-link`, data);
  }

  addContact(contact: { phoneNumber?: string, email?: string }) {
    let data = {contact: contact };
    return this.http.post(`/profile/add-contact`, data);
  }

  retrySendVerifyCode(contact: { phoneNumber?: string, email?: string }) {
    let data = {contact: contact };
    return this.http.post(`/profile/retry-send-verify-code`, data);
  }

  checkVerifyCodeContact(verifyCode: string, contact: { phoneNumber?: string, email?: string }) {
    let data = {verifyCode: verifyCode, contact: contact };
    return this.http.post(`/profile/check-verify-code-contact`, data);
  }

}
