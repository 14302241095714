import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private token: TokenService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.token.getToken(); 

    // PaymentService
    if (req.url.includes('/v2')) {
      req = req.clone({
        url: `https://seller-api.ilai.io/api${req.url}`,
        setHeaders: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } else {
      req = req.clone({
        url: `${environment.apiUrl}${req.url}`,
        setHeaders: {
          'Authorization': `Bearer ${token}`,
        },
      }); 
    }  
    return next.handle(req);
  }
}
